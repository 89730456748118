.componentWrapper {
    @apply py-0 align-middle min-w-full;
}

.wrapper {
    @apply overflow-x-auto pb-2;
}

.table {
    @apply min-w-full divide-y divide-lightGray overflow-x-auto;

    border-collapse:separate;
    border-spacing: 0 0.5em;
}

.header {
    @apply h-[50px] bg-lightGray text-cleanSlate py-6 !important;
}

.headerCell {
    @apply px-2 sm:px-4 py-4 !text-xs text-left text-cleanSlate font-medium capitalize tracking-wider;
}

.headerPlain {
    @apply flex flex-nowrap text-cleanSlate rounded-sm font-semibold;
    @apply focus:ring-2 focus:outline-none focus:ring-offset-0 focus:ring-lightGray !important;
}

.headerLink {
    @apply flex flex-nowrap text-cleanSlate cursor-pointer rounded-sm font-semibold;
    @apply focus:ring-2 focus:outline-none focus:ring-offset-0 focus:ring-lightGray !important;
}

.sort {
    @apply text-lightGray flex ml-1;
}

.body {
    @apply bg-white divide-y divide-lightGray rounded-md;
}

.bodyCell {
    @apply px-2 sm:px-4 py-4 !text-sm;
}

.selected {
    @apply bg-cleanSlate;
}

.bodyRow td:first-child {
    border-left-style: solid;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.bodyRow td:last-child {
    border-right-style: solid;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
}

.bodyRow {
    box-shadow: 0 3px 2px 0 rgb(0 0 0 / 5%);
    border-radius:8px;
}

.shrunk {
    max-width: 0;
}
