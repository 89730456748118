.mountainBackground {
    background-color: #434F54;
    background-image: url("/images/brief-background.svg");
    background-repeat: no-repeat;
    background-position: center top -120px;
    background-size: contain;
}

.headerMountainBackground{
    background-color: #fff;
    background-image: url("/images/grey-mountains.svg");
    background-repeat: no-repeat;
    background-position: bottom right;
}

@media (width <= 768px) {
    .mountainBackground {
        background-size: auto;
        background-position: center top -100px;
    }
}