.inputContainer {
    @apply flex items-center w-full cursor-pointer;
}

.inputWrapper {
    @apply w-10 h-5 relative;
}

.area {
    @apply w-10 h-5 left-0 top-0 absolute bg-zinc-200 rounded-full;
}

.dot {
    @apply w-4 h-4 left-[2.50px] top-[2px] absolute bg-white rounded-full;
}

input:checked ~ .area {
    @apply bg-neutral-600;
}

input:checked ~ .dot {
    @apply left-[21.17px];
}

.label {
    @apply text-neutral-600 text-sm ml-2 font-instrument font-medium !important;
}

.labelError {
    @apply text-red-600 !important;
}

.errorText {
    @apply bg-red-200 mt-2 py-2 px-4 text-xs text-red-600 rounded-sm !important;
}
