.error {
    @apply focus:ring-2 focus:ring-red-200 focus:border-red-600 border-red-600 text-red-600;
}

.errorText {
    @apply bg-red-200 mt-2 py-2 px-4 text-xs text-red-600 rounded-sm;
}

.arrow {
    background-image: url("/images/chevron-down.svg") !important;
    background-size: 8px !important;
    background-repeat: no-repeat !important;
}
