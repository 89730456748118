.container {
    @apply inline-flex flex-wrap -space-x-px text-sm ;

    user-select: none;
}

.previous {
    @apply cursor-pointer flex items-center justify-center px-3 h-8 leading-tight text-cleanSlate bg-white border border-gray rounded-l-lg hover:bg-gray-100 hover:text-black;
}

.next {
    @apply cursor-pointer flex items-center justify-center px-3 h-8 leading-tight text-cleanSlate bg-white border border-gray rounded-r-lg hover:bg-gray-100 hover:text-black;
}

.pagination {
    @apply cursor-pointer flex items-center justify-center px-3 h-8 leading-tight text-cleanSlate bg-white border border-gray hover:bg-gray-100 hover:text-black ;
}

.selected {
    @apply cursor-default bg-lightGray text-cleanSlate hover:bg-lightGray hover:text-cleanSlate;
}

.dots {
    @apply cursor-default flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray;
}
